import React, { Component } from 'react';
import {FaArrowLeft} from 'react-icons/fa';
import {FaArrowRight} from 'react-icons/fa';
import Navbar from './Navbar';
import '../css/Home.css';
import {FaInstagram, FaTwitter} from 'react-icons/fa';


class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      timerFlag: false,
      count: 0
  }
}


  nextSlide = e => {
    const slides = document.querySelectorAll('.slide');

    const current = document.querySelector('.current');
    current.classList.remove('current');

      if (current.nextElementSibling) {
        current.nextElementSibling.classList.add('current');
      } else {
        slides[0].classList.add('current');
      }
      setTimeout(() => current.classList.remove('current'));
    }



    prevSlide = e => {
      const slides = document.querySelectorAll('.slide');
  
      const current = document.querySelector('.current');
      current.classList.remove('current');

        if (current.previousElementSibling) {
          current.previousElementSibling.classList.add('current');
        } else {
          slides[slides.length - 1].classList.add('current');
        }
        setTimeout(() => current.classList.remove('current'));
      }


      componentDidMount() {
        this.myInterval = setInterval(() => {
          this.nextSlide();
        }, 6000)
    }

  
      componentWillUnmount(){
        clearInterval(this.myInterval);
      }


    render() {
    return (
      <div className="Homepage">
        <Navbar />
        <div className="sliderPage">
          <div className="slider">
            <div className="slide current">            
            </div>
            <div className="slide">
              <div className="content"> 
              <p style={{fontWeight: 'bold', fontSize: '1.4em', color: '#383434'}}>We have several options for the seasoned cigar<br/> veteran,
              an occasional partaker, and the first timer <br/> looking to get in on the cigar experience.</p>
              </div>              
            </div>
            <div className="slide">
              <div className="content"> 
              <p style={{fontWeight: 'bold', fontSize:'1.2em', fontFamily:'Georgia', color: '#383434'}}>Along with your cigar, you can relax in <br/>either of the two small lounges which include: </p>
              <ul className="cap" style={{fontWeight:'bold', fontSize:'1.3em'}}>
              <li>Comfortable leather furniture</li>
              <li>50-inch Flat Screen Televisions</li>
              <li>Free Wi-Fi</li>
              </ul>
              </div>              
            </div>
            <div className="slide">
              <div className="content"> 
              <p style={{fontWeight: 'bold', fontSize: '1.3em', color:'#383434'}}>Cigars and socializing are an excellent match. <br/>
              Stay tuned to our monthly calendar for promotional  <br/>events,
              cigar personality appearances, raffles and prizes.</p>
              </div>              
            </div>
          </div>
          <div className="buttons">
            <button id="prev" onClick={this.prevSlide}><FaArrowLeft size='2rem' /></button>
            <button id="next" onClick={this.nextSlide}><FaArrowRight size='2rem' /></button>
          </div>
        </div>
        <div className="notice">
          <h1>Attention Customers: </h1>
          <p>All locations are open to have customers in store with a face mask<br /> to follow state regulations.
            Please continue to follow the CDC's<br/> guidelines and stay home when sick. We look forward to seeing you!</p>
        </div>
        <div className="icons">
          <ul>
          {/* <a href="https://www.facebook.com/broadwaycigarcompany/"><FaFacebook size="4rem" color="black" /></a> */}
          <a href="https://twitter.com/503Cigars"><FaTwitter size="4rem" color="black" /></a>
          <a href="https://www.instagram.com/503cigars/"><FaInstagram size="4rem" color="black" /></a>
          </ul>
        </div>
      </div>
    );
    }
}

export default Home;
