import React, { Component } from 'react';
import Navbar from './Navbar';
import '../css/Home.css';
import {withScriptjs, withGoogleMap} from "react-google-maps";
import Location from './Location';
import {FaInstagram, FaTwitter} from 'react-icons/fa';
import Locaiton1 from '../images/Location_1.jpg';
import Location2 from '../images/Location_2.jpg'


const WrappedMap = withScriptjs(withGoogleMap(Location));


class Map extends Component {

      render() {
    return (
      <div>
        <Navbar />
      <div className="photogrid">
      <div className="row1">
        <div className="column1">
          <img className="album1" src={Locaiton1} alt="CigarImage" style={{width:"100%"}}/>
          </div>
          <div className="column1">
          <img className="album1" src={Location2} alt="CigarImage" style={{width:"100%"}}/>
        </div>
      </div>
      </div>
        <div className="map">
          <WrappedMap googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBfBrYU_PJTDkxvSoZJSMHi9b_VObpVN4A"}
          loadingElement={<div style={{height: "100%", width:"60%"}} />}
          containerElement={<div className="containn" />}
          mapElement={<div style={{height: "100%" }} />}
          />
          <h1 className="location">Our Business Hours</h1>
          <p className="address"> 15591 SE MCLOUGHLIN BLVD<br />
            OAK GROVE, OR 97267<br /><br />
            <b>MONDAY - SATURDAY :  </b>10:00AM - 7:00PM<br />
            <b>SUNDAY :  </b>10:00AM - 6:00PM<br /> <br />
            WWW.503CIGARS.COM<br /><br />
            (503) 387 - 6162</p>
        </div>
        <div className="iconS">
          <ul>
          {/* <a href="https://www.facebook.com/broadwaycigarcompany/"><FaFacebook size="4rem" color="black" /></a> */}
          <a href="https://twitter.com/503Cigars"><FaTwitter size="4rem" color="black" /></a>
          <a href="https://www.instagram.com/503cigars/"><FaInstagram size="4rem" color="black" /></a>
          </ul>
        </div>
      </div>
    );
      }
}

export default Map;




