import React from 'react';
import Navbar from './Navbar';
import {withRouter} from "react-router-dom";
import {FaInstagram, FaTwitter} from 'react-icons/fa';
import '../css/Cigars.css';



function Cigars(){

    return(
      <div className="cigarlist">
        <Navbar />
        <h1 className="fancyTitle">Cigars We Carry</h1>
        <table className="cigars">
          <tbody>
          <tr>
            <td style={{fontWeight: "bolder"}}>Padron</td>
            <td style={{fontWeight: "bolder"}}>Perdomo</td>
            <td style={{fontWeight: "bolder"}}>Tatuaje</td>
          </tr>
          <tr>
            <td style={{fontWeight: "bolder"}}>My Father Cigars</td>
            <td style={{fontWeight: "bolder"}}>Oliva</td>
            <td style={{fontWeight: "bolder"}}>PDR</td>
          </tr>
          <tr>
            <td style={{fontWeight: "bolder"}}>Arturo Fuente</td>
            <td style={{fontWeight: "bolder"}}>La Flor Dominicana</td>
            <td style={{fontWeight: "bolder"}}>Montecristo</td>
          </tr>
          <tr>
            <td>AVO</td>
            <td style={{fontWeight: "bolder"}}>Illusione Cigars</td>
            <td>El Rico Habano</td>
          </tr>
          <tr>
            <td>Aging Room</td>
            <td>Eratello</td>
            <td>Litto Gomez</td>
          </tr>
          <tr>
            <td>Alec Bradley</td>
            <td>GAR</td>
            <td>LFD</td>
          </tr>
          <tr>
            <td>Ashton</td>
            <td>Grand Cru</td>
            <td>L'Atelier</td>
          </tr>
          <tr>
            <td>Brickhouse</td>
            <td>Havana Honeys</td>
            <td>Macanudo</td>
          </tr>
          <tr>
            <td>Butera</td>
            <td>Hoya de Monterrey</td>
            <td>Matilde</td>
          </tr>
          <tr>
            <td>CAO Flavors</td>
            <td>Romeo</td>
            <td>NUB</td>
          </tr>
          <tr>
            <td>Camacho</td>
            <td>Illusione</td>
            <td>Nestor Miranda</td>
          </tr>
          <tr>
            <td>Casa Magna</td>
            <td>Jaime Garcia</td>
            <td>Partagas</td>
          </tr>
          <tr>
            <td>Cohiba</td>
            <td>La Aroma de Cuba</td>
            <td>Punch</td>
          </tr>
          <tr>
            <td>Diamond Crown</td>
            <td>San Cristobal</td>
            <td>Quesada</td>
          </tr>
          <tr>
            <td>Don Pepin</td>
            <td>La Aurora</td>
            <td>Regius</td>
          </tr>
          <tr>
            <td>Drew Estate</td>
            <td>La Duena</td>
            <td>EIROA by CLE</td>
          </tr>
          <tr>
            <td>La Gloria Cubana</td>
            <td>Romeo y Julieta</td>
            <td>La Palina</td>
          </tr>
          </tbody>
        </table>
        <div className="iconss">
          <ul>
          {/* <a href="https://www.facebook.com/broadwaycigarcompany/"><FaFacebook size="4rem" color="black" /></a> */}
          <a href="https://twitter.com/503Cigars"><FaTwitter size="4rem" color="black" /></a>
          <a href="https://www.instagram.com/503cigars/"><FaInstagram size="4rem" color="black" /></a>
          </ul>
        </div>
      </div>
    );
  }

export default withRouter(Cigars);

