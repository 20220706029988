import React from 'react';
import {GoogleMap, Marker} from "react-google-maps";
import "../css/Location.css"


function Location (){
    return (
      <GoogleMap 
      defaultZoom={15} 
      defaultCenter={{lat: 45.410220, lng: -122.627900 }}>
        <Marker key={20}
        position={{lat: 45.410220, lng: -122.627900}}
        />
        </GoogleMap>
    );
  }

export default Location;