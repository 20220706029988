import React, { Component } from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import '../css/App.css';

import Home from './Home';
import Events from './Events';
import Map from './Map';
import Cigars from './Cigars';
import Contact from './Contact';
import history from './history';

class App extends Component {

  render () {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/events" component={Events} />
          <Route path="/cigars" component={Cigars} />
          <Route path="/map" component={Map} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </Router>
    );
  }
}

export default App;
