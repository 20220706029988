import React, { Component } from 'react';
import Airtable from 'airtable';
import Navbar from './Navbar';
import "../css/Contact.css";
import {FaInstagram, FaTwitter} from 'react-icons/fa';




const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

class Contact extends Component{
  constructor(props) {
    super(props);
    this.state={
        contactFlag: true,
        mailFlag: false,
        name: null,
        email: null,
        subject: null,
        message: null,
        name2: null,
        email2: null,
        formErrors: {
            name: "",
            email: "",
            subject: "",
            message: "",
            name2: "",
            email2: ""
      }
    };
  }

  UNSAFE_componentWillMount(){
    const status = localStorage.getItem("MailList");
    if (status){
      this.setState({mailFlag: JSON.parse(status)});
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const {name} = this.state;
    const {email} = this.state;
    const {subject} = this.state;
    const {message} = this.state;
    const {formErrors} = this.state;
    

    if ((name&&email&&subject&&message)&&(!formErrors.name&&!formErrors.email&&!formErrors.subject&&!formErrors.message)){

        this.setState({contactFlag: false});
        // var airtable = require('airtable');
        var base = new Airtable({ apiKey: "keyDyweC1bmeHxboy"}).base("appXwMrFMKen3I6oa");

        const table = base('Contact List');

        const createRecord = async (fields) => {
            await table.create(fields);
        }
        createRecord({
            Name: name,
            Email: email,
            Subject: subject,
            Message: message
        });
}else{
    alert("Please fill the form appropriately");
}
  }


  handleSubmitt = e => {
    e.preventDefault();
    const {name2} = this.state;
    const {email2} = this.state;
    const {formErrors} = this.state;
    

    if ((name2&&email2)&&(!formErrors.name2&&!formErrors.email2)){

        this.setState({mailFlag: true});
        // var airtable = require('airtable');
        var base = new Airtable({ apiKey: "keyDyweC1bmeHxboy" }).base("appgqqhiJ0lgTUc9h");

        const table = base('Mailing List');

        const createRecord = async (fields) => {
            await table.create(fields);
        }
        createRecord({
            Name: name2,
            Email: email2,
        });
      }else{
        alert("Please fill the form appropriately");
      }
  }



  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch(name) {
        case 'name':
            formErrors.name = value.length < 2 ? 'minimum 2 characters required' : '';
            break;
        case 'email': 
            formErrors.email = emailRegex.test(value) ? '' : 'Invalid email address';
            break;
        case 'subject': 
            formErrors.subject = value.length < 6 ? 'minimum 6 characters required' : '';
            break;
        case 'message': 
            formErrors.message = value.length < 10 ? 'minimum 10 characters required' : '';
            break;
        case 'name2': 
            formErrors.name2 = value.length < 2 ? 'minimum 2 characters required' : '';
            break;
        case 'email2': 
            formErrors.email2 = emailRegex.test(value) ? '' : 'Invalid email address';            
            break;
        default:
            break;
    }
    this.setState({ formErrors, [name]: value});
  };
  

  render() {
    const {contactFlag} = this.state;
    const {mailFlag} = this.state;
    const {formErrors } = this.state;

    localStorage.setItem("MailList", JSON.stringify(this.state.mailFlag));
    return (
      <div>
        <Navbar />
        <div className="maindiv">
        {contactFlag ? '' : <h1 className="success">Success! Message Received.</h1>}
        <div className={contactFlag ? "show" : "hide"}>
            <h1 className="fancytitle">Contact Us</h1>
            <h3 className="caption">Have a question? Feel free to reach out, or send us an email. We'd be happy to help! </h3>
            <div className="form-wrapper">
            <form onSubmit={this.handleSubmit} noValidate>
            <div className="name">
                <input 
                className={formErrors.name.length > 0 ? "error" : null} 
                placeholder="Name" 
                type="name" 
                name="name" 
                noValidate
                onChange={this.handleChange}
                />
                {formErrors.name.length > 0 && (
                    <span className="errorMessage">{formErrors.name}</span>
                )}
                </div>

                <div className="email">
                <input 
                className={formErrors.email.length > 0 ? "error" : null} 
                placeholder="Email" 
                type="email" 
                name="email" 
                noValidate
                onChange={this.handleChange}
                />
                {formErrors.email.length > 0 && (
                    <span className="errorMessage">{formErrors.email}</span>
                )}
                </div>

                <div className="subject">
                <input  
                className={formErrors.subject.length > 0 ? "error" : null} 
                placeholder="Subject" 
                type="subject" 
                name="subject" 
                noValidate
                onChange={this.handleChange}
                />
                {formErrors.subject.length > 0 && (
                    <span className="errorMessage">{formErrors.subject}</span>
                )}
                </div>

                <div className="message">
                <textarea 
                className={formErrors.message.length > 0 ? "error" : null} 
                placeholder="Message" 
                type="message" 
                name="message" 
                noValidate
                onChange={this.handleChange}
                />
                {formErrors.message.length > 0 && (
                    <span className="errorMessage">{formErrors.message}</span>
                )}
                </div>
                <div className="createAccount">
                <button type="button" className="button" onClick={this.handleSubmit}>Send</button>
                </div>
            </form>
            </div>
        </div>
        {mailFlag ? <h1 className="success">Thanks for Subscribing! We will keep you updated.</h1> : ''}
          <div className={mailFlag ? "hide" : "show"}>
          <h3 className="caption2">Join our mailing list and never miss an update!</h3>
          <div className="form-wrapper2">
          <form onSubmit={this.handleSubmit} noValidate>
              <div className="name2">
                <input  
                className={formErrors.name2.length > 0 ? "error" : null} 
                placeholder="Name" 
                type="name2" 
                name="name2" 
                noValidate
                onChange={this.handleChange}
                />
                {formErrors.name2.length > 0 && (
                  <span className="errorMessage">{formErrors.name2}</span>
                )}
              </div>

              <div className="email2">
                <input
                className={formErrors.email2.length > 0 ? "error" : null} 
                placeholder="Email" 
                type="email2" 
                name="email2" 
                noValidate
                onChange={this.handleChange}
                />
                {formErrors.email2.length > 0 && (
                  <span className="errorMessage">{formErrors.email2}</span>
                )}
              </div>
              <div className="createAccount">
                <button type="button" className="button" onClick={this.handleSubmitt}>Subscribe Now</button>
              </div>
            </form>
          </div>
          </div>
        </div>
        <div className="iconss">
          <ul>
          {/* <a href="https://www.facebook.com/broadwaycigarcompany/"><FaFacebook size="4rem" color="black" /></a> */}
          <a href="https://twitter.com/503Cigars"><FaTwitter size="4rem" color="black" /></a>
          <a href="https://www.instagram.com/503cigars/"><FaInstagram size="4rem" color="black" /></a>
          </ul>
        </div>
      </div>
    );
  }
}

export default Contact;