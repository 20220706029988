import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Burger from './Burger';
import Logo from '../images/Logo.png';



const Nav = styled.nav`
width: 100%;
height: 200px;
padding: 0px 20px;
display: flex;
justify-content: space-between;
background-color: transparent;

.llogo{
  width: 245px;
  height: 200px;
}


@media only screen and (max-width: 768px) and (min-width: 300px) {
  .llogo{
    width: 180px;
    height: 160px;
  }
  }


`

const Navbar = () => {

    return (
      <Nav>
        <Link to="/">
        <img className="llogo" src={Logo} alt="Logo" />
        </Link>
        <Burger />
      </Nav>
    );
  }

export default Navbar;