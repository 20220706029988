import React, {Component} from 'react';
import FullCalender from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Navbar from './Navbar';
import Events1 from '../images/Events_1.jpg';
import Events2 from '../images/Events_2.jpg';
import {FaInstagram, FaTwitter} from 'react-icons/fa';
import '../css/Events.css';


class Events extends Component{

  constructor(props) {
    super(props);
    this.state = {
      events: []
    };
  }


  UNSAFE_componentWillMount() {
    fetch(`https://api.airtable.com/v0/app8F2GlTliSnwUc2/Calendar?api_key=keyDyweC1bmeHxboy`)
      .then(result => result.json())
      .then(result => 
        { 
          this.setState({events: result.records.map(single => {return single.fields})});
  
        }).catch(error => console.log(error))
  }


  render() {
    const {events} = this.state;

    const allevents = [];
    events.forEach((event) => {
      allevents.push(event);
    })

    const listEvents = [];
    events.forEach((day) => {
      listEvents.push(
        <li style={{fontWeight:"bolder"}}><h1 style={{color:"black", fontFamily:"fantasy, Arial, Helvetica, sans-serif"}}>{day.title}</h1></li>
      );
    });


    return (
      <div>
      <Navbar />
      <h1 className="fantitle">Upcoming Events</h1>
      <div id="events" className="calendarr">
      <FullCalender
      plugins={[ dayGridPlugin ]}
      initialView="dayGridMonth" 
      aspectRatio= "2"
      events={allevents}
      />
      </div>
      <div className="events">
      <ul className="eventList">
        {listEvents}
      </ul>
      </div>
      <div className="grid">
      <div className="row">
        <div className="column">
          {/* <img className="album" src={Logo} alt="photo1" style={{width:"95%"}}/> */}
          <img className="album" src={Events1} alt="photo2" style={{width:"95%"}}/>
        </div>
          <div className="column">
          {/* <img className="album" src={Logo} alt="photo3" style={{width:"95%"}}/> */}
          <img className="album" src={Events2} alt="photo4" style={{width:"95%"}}/>
          </div>
      </div>
      {/* <div className="row">
        <div className="column">
          <img className="album" src={Logo} alt="photo5" style={{width:"95%"}}/>
          </div>
          <div className="column">
          <img className="album" src={Logo} alt="photo5" style={{width:"95%"}}/>
        </div>
      </div> */}
      </div>
      <div className="iconsss">
        <ul>
          {/* <a href="https://www.facebook.com/broadwaycigarcompany/"><FaFacebook size="4rem" color="black" /></a> */}
          <a href="https://twitter.com/503Cigars"><FaTwitter size="4rem" color="black" /></a>
          <a href="https://www.instagram.com/503cigars/"><FaInstagram size="4rem" color="black" /></a>
          </ul>
        </div>
      </div>
    );
  }
};

export default Events;

